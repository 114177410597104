import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"

// @ts-ignore
const BioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
      // @ts-ignore
      <Layout location={location} title={siteTitle}>
        <SEO title="Contact - Vadim Polyakov" />
        <Nav />
        <div className="content">
            <p>Best way to contact me is via LinkedIn: <a href="https://www.linkedin.com/in/vadimpolyakov/">https://www.linkedin.com/in/vadimpolyakov/</a></p>
            <p>You can email me: vadim@&lt;this domain&gt;</p>
        </div>
    </Layout>
    )

}

export default BioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
